import { ConfigProvider } from 'antd';
import AppRoutes from 'routes/AppRoutes';
import NoticationPrivider from 'components/NoticationPrivider';

function App() {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#e7aa0c' } }}>
      <NoticationPrivider>
        <AppRoutes />
      </NoticationPrivider>
    </ConfigProvider>
  );
}

export default App;
