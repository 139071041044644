import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import GuardRouter from './GuardRouter';
import ROUTE_PAGES from './Routes';
import MainLayout from 'components/MainLayout';
import HomePage from 'pages/Home';
import LoginPage from 'pages/Login';
import ProfilePage from 'pages/Profile';
import ForgotPasswordPage from 'pages/ForgotPassword';
import ResetPasswordPage from 'pages/ResetPassword';

const GuardLayout = ({ children }) => {
  return (
    <GuardRouter>
      <MainLayout>{children}</MainLayout>
    </GuardRouter>
  );
};

const router = createBrowserRouter([
  {
    path: ROUTE_PAGES.LOGIN,
    element: <LoginPage />,
  },
  {
    path: ROUTE_PAGES.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
  },
  {
    path: ROUTE_PAGES.RESET_PASSWORD,
    element: <ResetPasswordPage />,
  },
  {
    path: ROUTE_PAGES.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
  },
  {
    path: ROUTE_PAGES.HOME,
    element: (
      <GuardLayout>
        <HomePage />
      </GuardLayout>
    ),
  },
  {
    path: ROUTE_PAGES.PROFILE,
    element: (
      <GuardLayout>
        <ProfilePage />
      </GuardLayout>
    ),
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} />;
};

export default AppRoutes;
//https://u33423332.ct.sendgrid.net/ls/click?upn=TEgvL-2FhRCuVH4nmTTyHl-2F1LkYwL2XJZziTFBh8gz-2Fvq-2BnxufXtRG9aIpxbZUMuJN2gsJouPjJYqGEMYuZFeYvdKrzaJTfQWfPYIjdmhhf7EvIRv6y288fljRjWzP2tTIF4VrtsXZtZVYVZXkl9YAq9O1eq1MXW-2FCW2bXkkWTX8jTqrBW9VrL4Ej5oBtwSOdfhXBisXjX1fhICx5pZVLTA1iDUW8xA9yTm4VBf844TwU-2FBIjf3z-2FXT0uL0qh5IncSDvFeVs5E17q5FL82RGFcTj9N35X6q96z-2Fwl2CubyiF9ICu-2FEf0466x9x8bpQqKDvWhohs0rwm2Inw-2B16lRQ0MQ-3D-3DP8lB_U-2FDrEV-2FcY31xfsaBr-2BOfUds1Hd93VPvWUyJAj7fP94DGvsenbyvHMVYD1Ni5YEwiBHDsSSuhjBS7cEWw9u-2BhYKMki02-2BrvBkia2Up0NGdoD9CvATHZkkW3rC-2Frdz25JhQCfdhQG6yz8f1202qwfV37BSVaMp9W7T1VSK9oQigz8etZqs5bCwhiquzMtvBqrpZ5bfzKMrRDI6AXIkUlT7-2Fw-3D-3D
