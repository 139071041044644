import React from 'react';
import capitalize from 'lodash/capitalize';

const ValidationField = ({ children, error, touched }) => {
  const hasError = error && touched;
  return (
    <>
      {React.cloneElement(children, { status: hasError ? 'error' : undefined })}
      {hasError && (
        <span className="text-red-500 mt-1 block text-xs">
          {capitalize(error)}
        </span>
      )}
    </>
  );
};

export default ValidationField;
