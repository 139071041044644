import React, { useMemo, useState } from 'react';
import { object, string } from 'yup';
import { Formik } from 'formik';
import { Button } from 'antd';
import { useAppContext } from 'context/AppContext';
import AffiliateService from 'services/AffiliateService';
import AuthService from 'services/AuthService';
import TextInput from 'components/FormControls/TextInput';
import { FormikPatchTouched } from 'components/FormControls/FormikPatchTouched';
import { useNotify } from 'components/NoticationPrivider';

const PROFILE_VALIDATION_SCHEMA = object({
  account: string().required('Dữ liệu bắt buộc'),
  bank: string().required('Dữ liệu bắt buộc'),
  name: string().required('Dữ liệu bắt buộc'),
  idenfier: string()
    .required('Dữ liệu bắt buộc')
    .matches(/\d{12}$/, 'Số căn cước không hợp lệ'),
  phone: string()
    .required('Dữ liệu bắt buộc')
    .matches(/^(0)\d{9}$/, 'Số điện thoại không hợp lệ'),
});

const PASSWORD_VALIDATION_SCHEMA = object({
  currentPassword: string()
    .required('Dữ liệu bắt buộc')
    .min(8, 'Mật khẩu phải có ít nhất 8 kí tự'),
  newPassword: string()
    .required('Dữ liệu bắt buộc')
    .min(8, 'Mật khẩu phải có ít nhất 8 kí tự'),
});

const ProfileForm = () => {
  const appContext = useAppContext();
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  const initialValues = useMemo(() => {
    if (appContext?.data) {
      return appContext.data;
    }
    return {};
  }, [appContext?.data]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await AffiliateService.fetchUpdateProfile(values);
      notify.success({
        message: 'Thành công',
        description: 'Thông tin đã được cập nhật',
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={PROFILE_VALIDATION_SCHEMA}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        values,
        touched,
        errors,
      }) => {
        return (
          <>
            <FormikPatchTouched />
            <div>
              <div className="mx-auto bg-white p-5 shadow-lg border rounded-sm">
                <p className="text-lg mt-0 font-medium">CẬP NHẬT THÔNG TIN</p>
                <div className="grid grid-cols-2 gap-4 md:grid-cols-1">
                  <div className="w-full mb-3">
                    <TextInput
                      disabled
                      label="Email"
                      value={values.email}
                      name="email"
                    />
                  </div>
                  <div className="w-full mb-3">
                    <TextInput
                      disabled
                      label="Mã CTV"
                      value={values.code}
                      name="code"
                    />
                  </div>
                  <div className="w-full mb-3">
                    <TextInput
                      disabled
                      label="Vị trí"
                      value={values.position}
                      name="position"
                    />
                  </div>
                  <div className="w-full mb-3">
                    <TextInput
                      required
                      label="Họ và tên"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.name}
                      error={errors.name}
                      value={values.name}
                      name="name"
                    />
                  </div>
                  <div className="w-full mb-3">
                    <TextInput
                      required
                      label="CCCD"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.idenfier}
                      error={errors.idenfier}
                      value={values.idenfier}
                      name="idenfier"
                    />
                  </div>
                  <div className="w-full mb-3">
                    <TextInput
                      required
                      label="Số điện thoại"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.phone}
                      error={errors.phone}
                      value={values.phone}
                      name="phone"
                    />
                  </div>
                  <div className="w-full mb-5">
                    <TextInput
                      required
                      label="Ngân hàng"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.bank}
                      error={errors.bank}
                      value={values.bank}
                      name="bank"
                    />
                  </div>
                  <div className="w-full mb-5">
                    <TextInput
                      required
                      label="Tài khoản ngân hàng"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.account}
                      error={errors.account}
                      value={values.account}
                      name="account"
                    />
                  </div>
                </div>
                <Button
                  loading={isLoading}
                  onClick={handleSubmit}
                  block
                  type="primary"
                >
                  LƯU
                </Button>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const PasswordForm = () => {
  const [isLoading, setLoading] = useState(false);
  const notify = useNotify();

  const initialValues = useMemo(() => {
    return {
      currentPassword: '',
      newPassword: '',
    };
  }, []);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await AuthService.fetchChangePassword(values);
      notify.success({
        message: 'Thành công',
        description: 'Mật khẩu đã được cập nhật',
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={PASSWORD_VALIDATION_SCHEMA}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({
        handleBlur,
        handleChange,
        handleSubmit,
        values,
        touched,
        errors,
      }) => {
        return (
          <>
            <FormikPatchTouched />
            <div className="mt-5">
              <div className="mx-auto bg-white p-5 shadow-lg border rounded-sm">
                <p className="text-lg mt-0 font-medium">CẬP NHẬT MẬT KHẨU</p>
                <div className="grid grid-cols-2 gap-4 md:grid-cols-1">
                  <div className="w-full mb-3">
                    <TextInput
                      type="password"
                      required
                      label="Mật khẩu cũ"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.currentPassword}
                      error={errors.currentPassword}
                      value={values.currentPassword}
                      name="currentPassword"
                    />
                  </div>
                  <div className="w-full mb-3">
                    <TextInput
                      type="password"
                      required
                      label="Mật khẩu mới"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched.newPassword}
                      error={errors.newPassword}
                      value={values.newPassword}
                      name="newPassword"
                    />
                  </div>
                </div>
                <Button
                  loading={isLoading}
                  onClick={handleSubmit}
                  block
                  type="primary"
                >
                  LƯU
                </Button>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

const ProfilePage = () => {
  return (
    <div>
      <ProfileForm />
      <PasswordForm />
    </div>
  );
};

export default ProfilePage;
