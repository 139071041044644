import HttpService from './HttpService';

async function fetchGetProfile() {
  return await HttpService.Get('/affiliates/me');
}

async function fetchUpdateProfile(values) {
  return await HttpService.Put('/affiliates/me', values);
}

const AffiliateService = {
  fetchGetProfile,
  fetchUpdateProfile,
};

export default AffiliateService;
