import React, { useMemo } from 'react';
import { Button, List } from 'antd';
import {
  PushpinOutlined,
  HomeOutlined,
  CopyOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import shared from 'shared';
import classNames from 'classnames';
import Constants from 'config/Constants';
import useProducts from 'hooks/useProducts';
import { useAppContext } from 'context/AppContext';
import { useNotify } from 'components/NoticationPrivider';

const ProductStatus = ({ status }) => {
  const isAvailable =
    status === shared.Constants.PRODUCT_STATUS.AVAILABLE.VALUE;
  return (
    <div
      className={classNames(
        'absolute text-white text-sm px-2 py-1 font-medium right-2 top-3',
        {
          'after:border-t-red-500': !isAvailable,
          'bg-red-500': !isAvailable,
          'after:border-t-green-500': isAvailable,
          'bg-green-500': isAvailable,
        }
      )}
    >
      {isAvailable ? 'CÒN TRỐNG' : 'ĐÃ CHO THUÊ'}
    </div>
  );
};

const HomePage = () => {
  const notify = useNotify();
  const appContext = useAppContext();
  const items = useMemo(() => {
    return appContext?.data.products;
  }, [appContext?.data]);
  const { data } = useProducts({ items, page: 1, limit: 20 });

  const handleCopy = (item) => {
    const url = `${Constants.WEB_URL}/thue-phong/${item.slug}?affiliate=${appContext?.data.code}`;
    navigator.clipboard.writeText(url);
    notify.success({
      message: 'Thành công',
      description: 'Đã sao chép đường dẫn',
    });
  };

  const handleOpenLink = (item) => {
    window.open(
      `${Constants.WEB_URL}/thue-phong/${item.slug}?affiliate=${appContext?.data.code}`,
      '_blank'
    );
  };

  const renderItem = (item, index) => {
    return (
      <div
        key={index}
        className="flex relative items-start bg-white mb-2 shadow rounded sm:flex-col"
      >
        <div className="relative w-[120px] sm:w-full h-[130px] sm:h-[250px] overflow-hidden bg-black">
          <img
            className="w-full h-[150px] sm:h-[300px] absolute top-[-5px] sm:top-0 left-0 object-cover"
            src={
              item.images.length > 0
                ? item.images[0].src
                : '/images/logo_white.png'
            }
            alt="Logo"
          />
        </div>
        <div className="ml-3 flex-1 w-full sm:ml-0 sm:px-3">
          <p className="mb-0 mt-2 font-semibold">
            {item.code} - {item.title}
          </p>
          <p className="m-0 text-red-500 font-medium">
            {shared.currencyFormat(item.price)}
          </p>
          <div className="flex items-center sm:flex-col sm:items-start sm:my-2">
            <div className="flex text-sm items-center">
              <PushpinOutlined />
              <p className="m-0 ml-1">Địa chỉ: {item.address}</p>
            </div>
            <div className="flex text-sm items-center ml-3 sm:ml-0">
              <HomeOutlined />
              <p className="m-0 text-sm ml-1">
                Đặc điểm: {item.specifications.map((v) => v.name).join(', ')}
              </p>
            </div>
          </div>
          <div className="flex justify-end sm:justify-between border w-full border-solid border-gray-200 border-b-0 border-r-0 border-l-0 mt-2 py-2">
            <div>
              <Button
                onClick={() => handleCopy(item)}
                type="primary"
                color="primary"
                icon={<CopyOutlined />}
              >
                Sao chép<span className="ml-1 sm:!hidden">với mã CTV</span>
              </Button>
            </div>
            <div className="mx-3 sm:mx-0">
              <Button
                onClick={() => handleOpenLink(item)}
                color="primary"
                icon={<LinkOutlined />}
              >
                Xem<span className="ml-1 sm:!hidden">liên kết</span>
              </Button>
            </div>
          </div>
        </div>
        <ProductStatus status={item.status} />
      </div>
    );
  };
  return (
    <List
      itemLayout="horizontal"
      dataSource={data ?? []}
      renderItem={renderItem}
    />
  );
};

export default HomePage;
